import React from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { Components } from "@episerver/spa-core";
import clsx from "clsx";
import { useStyles } from "./Footer.style";
import { LinkWithArrow } from "../LinkWithArrow";
import isomorphicMap from "../../extensions";
import { usePageContext } from "../Context/PageContextProvider";
export const Footer = React.memo(() => {
    const { startPageData } = usePageContext();
    const classes = useStyles();
    const footerLinksArray = Array();
    if (Array.isArray(startPageData?.footerLinksBottom?.value)) {
        startPageData.footerLinksBottom.value.forEach((item) => {
            footerLinksArray.push({
                href: item.href,
                text: item.text,
            });
        });
    }
    const showLogo = ({ logoSource, logoClass, altText }) => {
        if (!logoSource)
            return;
        const wrapperLogoClassProp = (logoClass + "Wrapper");
        const logoClassProp = logoClass;
        return (React.createElement("a", { className: classes[wrapperLogoClassProp], href: startPageData.headerBlock.logoUrl.value },
            React.createElement("img", { className: classes[logoClassProp], src: logoSource, alt: altText })));
    };
    const showFooterLinksBlocks = () => {
        if (!startPageData?.footerLinksContainerBlock?.footerLinksBlocks?.value)
            return null;
        return (React.createElement(Box, { className: classes.wrapper },
            React.createElement(Container, { disableGutters: true, className: clsx(classes.footerWrapper, classes.container) },
                React.createElement(Grid, { container: true, direction: "row", className: classes.footerContainer },
                    React.createElement(Components.ContentArea, { addContainer: false, defaultContainerClass: "footer-links-wrapper", defaultBlockClass: "footer-links-item", noWrap: true, data: startPageData.footerLinksContainerBlock.footerLinksBlocks })))));
    };
    return (React.createElement("footer", { className: clsx(classes.footerNoOverflow, "border-top mt-5") },
        showFooterLinksBlocks(),
        React.createElement(Container, { disableGutters: true, className: classes.footerBottomWrapper },
            React.createElement("div", { className: classes.footerLogosWrapper },
                showLogo({
                    logoSource: startPageData.headerBlock?.moFooterLogo?.value?.url,
                    logoClass: "logo",
                    altText: startPageData.headerBlock.altTextMoLogo?.value ?? "Primary logo",
                }),
                showLogo({
                    logoSource: startPageData.headerBlock?.moAdditionaFooterlLogo?.value?.url,
                    logoClass: "additionalLogo",
                    altText: startPageData.headerBlock.altTextMoAdditionalFooterLogo?.value ??
                        "Secondary logo",
                })),
            React.createElement("div", { className: classes.footerTextWrapper },
                React.createElement("div", { className: classes.aboutFooterTextWrapper },
                    React.createElement(Typography, { variant: "body1", component: "span", className: classes.aboutFooterText, dangerouslySetInnerHTML: {
                            __html: startPageData?.aboutContent.value,
                        } })),
                React.createElement("div", { className: classes.bottomLinkWrapper }, isomorphicMap(footerLinksArray, (link, index) => {
                    return (React.createElement(LinkWithArrow, { key: index, link: { url: link.href, text: link.text } }));
                }))))));
});
Footer.displayName = "Footer";
export default Footer;
