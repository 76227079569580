import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    footerWrapper: {
        paddingRight: 8,
        paddingLeft: 8,
        paddingBottom: 24,
    },
    footerContainer: {
        justifyContent: "space-between",
        gap: 24,
    },
    wrapper: {
        marginTop: 32,
        marginBottom: 0,
        paddingTop: 40,
        paddingBottom: 40,
        borderTop: theme.teaser?.root?.border ? `1px solid ${theme.footer?.borderColor}` : "",
        borderBottom: theme.teaser?.root?.border ? `1px solid ${theme.footer?.borderColor}` : "",
        background: "linear-gradient(to bottom, " + theme.footer?.backgroundColor + " 0%, " + theme.footer?.backgroundColor + " 100%) no-repeat",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            backgroundPosition: "top",
            paddingBottom: 0,
        },
        ...theme.customStyling?.Footer?.wrapper,
    },
    footerBottomWrapper: {
        minHeight: "100px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 32,
        // [theme.breakpoints.down("sm")]: {
        //   textAlign: theme.footer?.mobileFooterPlacement ?? "center",
        // },
        maxWidth: "100%",
        ...theme.customStyling?.Footer?.footerBottomWrapper,
    },
    aboutFooterTextWrapper: {
        "& p": {
            marginTop: "0px",
            marginBottom: "0px",
        },
    },
    aboutFooterText: {
        ...theme.customStyling?.Footer?.aboutFooterText,
    },
    bottomLinkContainer: {
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            paddingBottom: 24,
        },
    },
    container: {
        maxWidth: 1120,
        textAlign: "left",
    },
    footerNoOverflow: {
        overflow: "hidden",
        ...theme.customStyling?.Footer?.footerNoOverflow,
    },
    footerLogosWrapper: {
        width: "100%",
        maxWidth: 1120,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 24,
        paddingRight: 24,
        boxSizing: "border-box",
    },
    logoWrapper: {
        display: "flex",
        alignItems: "center",
        ...theme.customStyling?.Footer?.logoWrapper,
    },
    logo: {
        height: 40,
        width: 124,
        "&:hover": {
            backgroundColor: "transparent",
        },
        [theme.breakpoints.up("sm")]: {
            width: 180,
        },
    },
    additionalLogoWrapper: {
        display: "flex",
        alignItems: "center",
        ...theme.customStyling?.Footer?.additionalLogoWrapper,
    },
    additionalLogo: {
        height: 40,
        width: 124,
        "&:hover": {
            backgroundColor: "transparent",
        },
        [theme.breakpoints.up("sm")]: {
            width: 180,
        },
    },
    footerTextWrapper: {
        width: "100%",
        maxWidth: 1120,
        display: "grid",
        gridAutoFlow: "row",
        columnGap: 24,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 24,
        paddingRight: 24,
        marginTop: 24,
        marginBottom: 80,
        boxSizing: "border-box",
        [theme.breakpoints.up("md")]: {
            gridAutoFlow: "column",
            gridAutoColumns: "440px auto",
        },
        ...theme.customStyling?.Footer?.footerTextWrapper
    },
    bottomLinkWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        marginTop: 24,
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            marginTop: 0,
        },
        ...theme.customStyling?.Footer?.bottomLinkWrapper,
    },
}));
